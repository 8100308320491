// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-about-mdx": () => import("/zeit/4cd8a442/content/about.mdx" /* webpackChunkName: "component---content-about-mdx" */),
  "component---content-index-mdx": () => import("/zeit/4cd8a442/content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---content-data-confidence-mdx": () => import("/zeit/4cd8a442/content/data/confidence.mdx" /* webpackChunkName: "component---content-data-confidence-mdx" */),
  "component---content-data-index-mdx": () => import("/zeit/4cd8a442/content/data/index.mdx" /* webpackChunkName: "component---content-data-index-mdx" */),
  "component---content-data-resources-mdx": () => import("/zeit/4cd8a442/content/data/resources.mdx" /* webpackChunkName: "component---content-data-resources-mdx" */),
  "component---content-data-statistics-and-machine-learning-mdx": () => import("/zeit/4cd8a442/content/data/statistics-and-machine-learning.mdx" /* webpackChunkName: "component---content-data-statistics-and-machine-learning-mdx" */),
  "component---content-maths-index-mdx": () => import("/zeit/4cd8a442/content/maths/index.mdx" /* webpackChunkName: "component---content-maths-index-mdx" */),
  "component---content-maths-topic-mdx": () => import("/zeit/4cd8a442/content/maths/topic.mdx" /* webpackChunkName: "component---content-maths-topic-mdx" */),
  "component---content-maths-zoetrope-mdx": () => import("/zeit/4cd8a442/content/maths/zoetrope.mdx" /* webpackChunkName: "component---content-maths-zoetrope-mdx" */)
}

